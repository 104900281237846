.login-signup-container {
	main {
		@include main-glass-background;
		.glass {
			@include main-glass;
			display: flex;
		}
		@media (max-width: 768px) {
			.glass {
				flex-direction: column;
			}
		}
	}
	.circle {
		@include circle-glass-background;
	}
	.c-one {
		@include circle-glass-background-position(20%, 70%, -45deg);
	}
	.c-two {
		@include circle-glass-background-position(55%, 5%, -90deg);
	}
}
.left-pane {
	flex: 1;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	background: linear-gradient(to right bottom, rgba(255, 255, 255, 0.6), rgba(255, 255, 255, 0.3));
	border-radius: var(--soft-radius) 0rem 0rem var(--soft-radius);
	h1 {
		font-size: var(--clamped-medium-text);
		font-weight: 600;
		color: var(--maroon);
		line-height: 121.5%;
		text-align: center;
	}
	.change-auth-method {
		display: flex;
		flex-direction: column;
		align-items: center;
		h3 {
			font-size: var(--clamped-small-text);
			font-weight: 600;
			color: var(--maroon);
			line-height: 121.5%;
			text-align: center;
			text-decoration: none;
		}
		button {
			border: none;
			border-radius: 135px;
			height: 2.5rem;
			background-color: var(--gold);
			outline: none;
			font-size: var(--clamped-small-text);
			opacity: 0.85;
			&:hover {
				cursor: pointer;
				background: var(--gold-darkened);
			}
			h1 {
				width: 90%;
				margin: auto;
			}
		}
	}
}
@media (max-width: 768px) {
	.left-pane {
		border-radius: var(--soft-radius);
	}
}

.right-pane {
	flex: 2;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	position: relative;
	form {
		width: 90%;
	}
	.input-fields {
		display: flex;
		flex-direction: column;
	}
	.sign-in-button {
		border: none;
		border-radius: 135px;
		height: 2.5rem;
		width: 30%;
		background-color: var(--gold);
		outline: none;
		font-size: var(--clamped-small-text);
		&:hover {
			cursor: pointer;
			background: var(--gold-darkened);
		}
		h1 {
			width: 90%;
			margin: auto;
		}
	}
}
