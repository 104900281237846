.my-account-wrapper {
	position: absolute;
	width: 90%;
	height: 90%;
	margin: 0% 5%;
}

.header-wrapper {
	display: flex;
	h3 {
		padding: 0 1vw;
		font-weight: 500;
		font-size: var(--clamped-shmedium-text);
		cursor: pointer;
		border-bottom: 2px solid var(--light-grey);
		color: var(--light-grey);
	}
	.acc-active {
		border-bottom: 2px solid var(--maroon);
		color: var(--maroon);
	}
	.acc-non-active:hover {
		border-bottom: 2px solid var(--dark-grey);
		color: var(--dark-grey);
	}
}
