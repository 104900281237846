.input-error {
	display: flex;
	justify-content: center;
	align-items: center;
	.message {
		color: var(--red-error);
		margin-left: 12px;
		font-size: var(--clamped-small-text);
	}
}
