.overview-container {
	width: 100%;
	position: absolute;
	main {
		@include main-glass-background(70vh);
		.glass {
			@include main-glass(60vh, 95%);
			display: flex;
			justify-content: space-evenly;
			align-items: center;
			.right-cont {
				display: flex;
				flex-direction: column;
				.inv-data-cont {
					flex-grow: 1;
				}
				.inv-trans-cont {
					margin-top: 2rem;
					flex-grow: 2;
				}
			}
			.g-container {
				min-height: 55vh;
				width: 45%;
			}
			.card {
				background: linear-gradient(to left top, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0.8));
				border-radius: var(--soft-radius);
				box-shadow: 6px 6px 20px rgba(0, 0, 0, 0.4);
			}
			.overview-header {
				font-size: var(--clamped-medium-plus-text);
				font-weight: 600;
				color: var(--maroon);
				padding-left: 1.5rem;
				padding-top: 0.5rem;
			}
		}
	}
	.circle {
		@include circle-glass-background;
	}
	.c-one {
		@include circle-glass-background-position(60%, 85%, -45deg);
	}
	.c-two {
		@include circle-glass-background-position(10%, 2%, -45deg);
	}
}
