.acct-lp {
	background: var(--maroon);
	border-radius: var(--soft-radius) 0px 0px var(--soft-radius);
	height: 65vh;
	flex-grow: 1;
	display: flex;
	flex-direction: column;
	.lp-header {
		display: flex;
		align-items: center;
		width: 100%;
		justify-content: center;
		color: var(--text-light);
		p {
			margin-left: 0.5rem;
			font-weight: 600;
			font-size: 1.5rem;
		}
	}
	.lp-menu {
		display: flex;
		width: 100%;
		flex-direction: column;
		.lp-menu-option {
			display: flex;
			color: #ad8d95;
			align-items: center;
			svg {
				flex-grow: 1;
			}
			p {
				flex-grow: 3;
				font-size: 1.25rem;
			}
		}
		.lp-active {
			background: var(--background-light);
			color: var(--maroon);
		}
		.lp-inactive {
			&:hover {
				background: var(--maroon-darkened);
				cursor: pointer;
			}
		}
	}
}
