.input-row {
	display: flex;
	flex-direction: column;
	width: 100%;
	margin: 1vh 0vh;
	background: linear-gradient(to right bottom, rgba(255, 255, 255, 0.6), rgba(255, 255, 255, 0.3));
	border-radius: 30px;
	@media (max-width: 768px) {
		.input-field {
			flex-direction: column;
		}
	}
	.input-field {
		display: flex;
		width: 100%;
		align-items: center;
		p {
			flex: 1;
			text-align: center;
			color: var(--orange);
			font-size: 2.5vh;
		}
		input {
			flex: 2;
			min-height: 5vh;
			margin: auto 2vw auto 0vw;
			background: rgba(255, 252, 252, 0.75);
			border-radius: 30px;
			border: none;
			caret-color: var(--maroon);
			color: var(--maroon);
			text-align: center;
			font-size: 2.5vh;
			&:focus {
				outline: none;
				background: rgba(238, 238, 228, 0.972);
			}
			&:hover {
				border: 1px solid rgba(255, 252, 252, 0.75);
			}
		}
		.error {
			border: 2px solid var(--red-error);
			caret-color: var(--red-error);
		}
	}
}
