.home-container {
	position: fixed;
	height: 85%;
	width: 95%;
	.triangle {
		position: absolute;
		top: 0;
		left: 0;
	}
	.y {
		z-index: 1;
	}
	.m {
		z-index: 0;
	}
}

.half {
	position: absolute;
	height: 100%;
	width: 50%;
	z-index: 2;
	display: flex;
}
.l {
	left: 0;
	align-items: flex-end;
	.home-icon {
		margin-left: 3vw;
	}
	@media (max-width: 768px) {
		.home-icon {
			display: none;
		}
	}
}
.r {
	right: 0;
	justify-content: flex-start;
	align-items: center;
	.home-lmb {
		position: absolute;
		right: 15%;
		bottom: 15%;
	}
}
