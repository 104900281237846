@mixin main-glass-background($min-height: 75vh) {
	min-height: $min-height;
	background: linear-gradient(248.66deg, rgba(250, 181, 5, 0.9) 0%, rgba(122, 0, 33, 0.9) 100%);
	border-radius: var(--soft-radius);
	display: flex;
	align-items: center;
	justify-content: center;
}

@mixin circle-glass-background($diameter: 20rem) {
	background: var(--background-light);
	background: linear-gradient(to right top, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.2));
	height: $diameter;
	width: $diameter;
	position: absolute;
	border-radius: 50%;
}

@mixin circle-glass-background-position($top, $left, $degRotate) {
	top: $top;
	left: $left;
	transform: rotate($degRotate);
}

@mixin main-glass($min-height: 60vh, $width: 70%) {
	background: var(--background-light);
	min-height: $min-height;
	width: $width;
	background: linear-gradient(to right bottom, rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.2));
	border-radius: var(--soft-radius);
	z-index: 2;
	backdrop-filter: blur(2rem);
}
