* {
	font-family: 'Catamaran', sans-serif;
	--maroon: hsl(344, 100%, 24%);
	--maroon-darkened: hsla(344, 100%, 24%, 0.65);
	--gold: hsl(48, 100%, 60%);
	--gold-darkened: hsla(48, 100%, 60%, 0.726);
	--green-deposit: hsl(104, 92%, 26%);
	--red-withdrawal: hsl(0, 89%, 43%);
	--light-grey: hsl(0, 0%, 56%);
	--dark-grey: hsl(0, 0%, 25%);
	--orange: hsl(31, 71%, 46%);
	--red-error: hsla(0, 91%, 49%, 0.931);
	--text-light: hsl(0, 0%, 100%);
	--text-dark: hsl(0, 0%, 9%);
	--background-light: hsl(0, 0%, 100%);
	--soft-radius: 3rem;
	--clamped-very-small-text: clamp(8px, 0.75vw, 18px);
	--clamped-small-text: clamp(12px, 1.5vw, 36px);
	--clamped-shmedium-text: clamp(18px, 1.75vw, 42px);
	--clamped-medium-text: clamp(24px, 2vw, 48px);
	--clamped-medium-plus-text: clamp(30px, 2.25vw, 54px);
	--clamped-very-large-text: clamp(48px, 8vw, 84px);
}

#root {
	position: fixed;
	width: 100%;
	height: 100%;
}

.app {
	position: absolute;
	width: 95%;
	height: 95%;
	margin: 0 2.5%;
	box-sizing: border-box;
	overflow-y: scroll;
	overflow-x: hidden;
	a {
		text-decoration: none;
	}
}

@import './GlassMixins';
@import './Nav';
@import './MyAcctContainer';
@import './Accounting';
@import './Overview';
@import './InvData';
@import './InvPerf';
@import './InvTrans';
@import './Transaction';
@import './Authentication';
@import './SignUpField';
@import './SignUpControl';
@import './ErrorMessage';
@import './AccountingLeftPane';
@import './AccountingRightPane';
@import './Home';
@import './LearnMoreButton';
@import './Typewriter';
@import './OurStory';
