.inv-data-header-container {
	display: flex;
	justify-content: space-between;
	.more-details-container {
		display: flex;
		align-items: center;
		padding-right: 1.5rem;
		padding-top: 0.5rem;
		cursor: pointer;
		p {
			margin: 0 0.5rem 0 0;
			font-size: var(--clamped-small-text);
			color: var(--light-grey);
		}
	}
	.more-details-container:hover {
		p {
			color: var(--dark-grey);
		}
		path {
			fill: var(--dark-grey);
		}
	}
}
.acct-bal {
	margin: 0.1vw 0vw 0vw 0.1vw;
	font-weight: 400;
	font-size: var(--clamped-very-large-text);
	color: var(--light-grey);
	text-align: center;
}
