.desktop-nav-container {
	background: var(--background-light);
	z-index: 1;
	top: 0;
	position: -webkit-sticky;
	position: sticky;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.logo-link {
	text-decoration: none;
}

.logo-container {
	display: flex;
	align-items: center;
	cursor: pointer;
	text-decoration: none;
	.logo {
		margin-right: 0.5rem;
	}
	h1 {
		font-weight: 600;
		color: var(--maroon);
	}
}

.navigation {
	display: flex;
	a {
		text-decoration: none;
	}
	h1 {
		font-weight: 300;
		margin: 0px 16px;
		cursor: pointer;
		font-size: 2.3vw;
		color: var(--dark-grey);
	}
	.nav-active {
		font-weight: 500;
	}
}

.account-header-container {
	display: flex;
	align-items: center;
	h1 {
		font-weight: 500;
		font-size: 1.4vw;
		cursor: pointer;
		color: var(--dark-grey);
	}
	button {
		border: none;
		border-radius: 135px;
		height: 2.5rem;
		width: 8vw;
		background-color: var(--gold);
		margin-left: 2rem;
		outline: none;
		font-size: 1.4vw;
		&:hover {
			cursor: pointer;
			background: var(--gold-darkened);
		}
		h1 {
			width: 90%;
			margin: auto;
		}
	}
}
