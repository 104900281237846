.transaction {
	td {
		text-align: center;
		font-size: var(--clamped-shmedium-text);
		width: 5vw;
		font-style: normal;
	}
	.t-date {
		font-weight: 300;
		color: var(--light-grey);
	}
	t-amt {
		font-weight: 500;
	}
}

.deposit {
	.t-amt {
		color: var(--green-deposit);
	}
}

.withdrawal {
	t-amt {
		color: var(--red-withdrawal);
	}
}
