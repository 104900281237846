.transactions-container {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;

	.trans-table-wrapper {
		overflow: hidden;
		overflow-y: scroll;
		max-height: 12rem;
		table {
			width: 100%;
			margin-top: 0.5vw;
		}
		table,
		td {
			border-collapse: collapse;
			table-layout: auto;
		}
	}
}
