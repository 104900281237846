.our-story-container {
	.banner-image {
		position: relative;
		img {
			width: 100%;
			height: auto;
		}
		.text-overlay {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			color: var(--text-light);
			font-style: normal;
			font-weight: bold;
			font-size: 6vw;
		}
	}
	.section-one {
		margin: 3vw 0;
		img {
			max-width: 100%;
			height: auto;
		}
		display: grid;
		grid-template-columns: 40% 60%;
		row-gap: 3vw;
		div {
			display: flex;
		}
		.image {
			justify-content: center;
			align-items: center;
		}
		.text {
			flex-direction: column;
			align-items: center;
			justify-content: flex-start;
			h1 {
				color: var(--maroon);
				font-weight: bold;
				font-size: 4vw;
				text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
			}
			p {
				margin: 0 10%;
				font-style: normal;
				font-weight: normal;
				font-size: var(--clamped-small-text);
			}
		}
	}
	@media (max-width: 768px) {
		.section-one {
			display: block;
		}
	}
	.horiz-divider {
		width: 85%;
		margin: auto;
		border-radius: 50px;
		border: 6px solid var(--light-grey);
	}
	.section-two {
		display: flex;
		flex-direction: column;
		align-items: center;
		h1 {
			color: var(--maroon);
			font-weight: bold;
			font-size: 5vw;
			text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
		}
		.bios {
			width: 100%;
			display: flex;
			justify-content: space-evenly;
			div {
				display: flex;
				flex-direction: column;
				align-items: center;
				h3 {
					font-style: normal;
					font-weight: normal;
					font-size: 2.5vw;
					margin-top: 1vw;
				}
				img {
					max-width: 100%;
					height: auto;
				}
			}
		}
	}
}
