.learn-more-button {
	padding: 1em 2.1em 1.1em;
	border-radius: 3px;
	margin: 8px 8px 8px 8px;
	display: inline-block;
	-webkit-transition: 0.3s;
	-moz-transition: 0.3s;
	-o-transition: 0.3s;
	transition: 0.3s;
	font-weight: 800;
	font-size: var(--clamped-very-small-text);
	text-align: center;
	text-decoration: none;
	-webkit-box-shadow: 0em -0.3rem 0em rgba(0, 0, 0, 0.1) inset;
	-moz-box-shadow: 0em -0.3rem 0em rgba(0, 0, 0, 0.1) inset;
	box-shadow: 0em -0.3rem 0em rgba(0, 0, 0, 0.1) inset;
	position: relative;
	border-radius: 50em !important;
	&hover {
		opacity: 0.8;
	}
	&focus {
		opacity: 0.8;
	}
}

.lmb-y {
	background-color: var(--gold);
	color: var(--text-dark);
	&:hover {
		background: var(--gold-darkened);
	}
}
.lmb-m {
	background-color: var(--maroon);
	color: var(--text-light);
	&:hover {
		background: var(--maroon-darkened);
	}
}
