.typewriter-container {
	h3 {
		font-style: normal;
		font-weight: 300;
		font-size: 5vw;
		line-height: 5vw;
		margin: 0;
	}
	.tw-content {
		margin-top: 3vw;
		display: flex;
		h1 {
			font-style: normal;
			font-weight: bold;
			font-size: 6vw;
			margin: 0;
		}
		.word {
			border-right: 1px solid black;
		}
		.placeholder {
			color: var(--background-light);
		}
	}
}
