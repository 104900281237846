.signUpControl {
	display: flex;
	width: 100%;
	position: absolute;
	top: 80%;
	justify-content: space-evenly;
	align-items: center;
	margin-top: 1vw;
	.left-arrow {
		display: flex;
		align-items: center;
		justify-content: flex-end;
		cursor: pointer;
		width: 4vw;
	}
	.right-arrow {
		display: flex;
		align-items: center;
		justify-content: flex-start;
		cursor: pointer;
		width: 4vw;
	}
	span {
		left: 75%;
	}
	.sign-up-button {
		border: none;
		border-radius: 135px;
		height: 2.5rem;
		width: 30%;
		background-color: var(--gold);
		outline: none;
		font-size: var(--clamped-small-text);
		&:hover {
			cursor: pointer;
			background: var(--gold-darkened);
		}
		h1 {
			width: 90%;
			margin: auto;
		}
	}
}
@media (max-width: 768px) {
	.signUpControl {
		position: static;
	}
}
