.acct-rp {
	height: 65vh;
	border-radius: 0px 3rem 3rem 0px;
	flex-grow: 5;
	.acct-table-container {
		width: 95%;
		height: 90%;
		margin: 2.5% 1.25% 2.5% 1.25%;
		background: var(--maroon);
		border-radius: 2rem 2rem 0rem 0rem;
		overflow: hidden;
		overflow-y: scroll;
		table {
			width: 100%;
			margin: auto;
			border-collapse: collapse;
			.header-row {
				height: 3rem;
				th {
					color: var(--text-light);
					font-weight: 600;
					position: sticky;
					top: 0;
					box-shadow: 0 2px 2px -1px rgba(0, 0, 0, 0.4);
					background: var(--maroon);
					text-align: left;
					padding-left: 1vw;
				}
			}
			tr {
				td {
					max-width: 100%;
					white-space: nowrap;
				}
				&:nth-child(even) {
					td {
						background: linear-gradient(
							to bottom,
							hsla(344, 100%, 23.9%, 0.3),
							hsla(344, 100%, 23.9%, 0.1)
						);
					}
				}
				&:hover {
					background: rgb(243, 243, 243);
				}
			}
			.data-row {
				height: 3rem;
				background: var(--background-light);
				td {
					text-align: left;
					padding-left: 1vw;
					.actions-container {
						display: flex;
						align-items: center;
						justify-content: space-evenlyå;
						svg {
							margin: 0.1vw;
							&:hover {
								path {
									fill: var(--orange);
								}
							}
						}
					}
				}
			}
		}
		.acct-add-transaction {
			background: blue;
			width: 100%;
		}
	}
}
